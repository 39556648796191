<template>
  <div>
    <df-btn
      v-if="!isInMobileXcs"
      type="special"
      :class="`subscription-btn px-4 py-[10px] font-sans-bold !text-[#A56409] pc:!h-10`"
      @click="navigateTo('/subscription/pricing')"
    >
      Subscription
    </df-btn>
  </div>
</template>
<script setup lang="ts">
const isInMobileXcs = ref(false);
onMounted(() => {
  isInMobileXcs.value = isInMobileApp();
});
</script>

<style scoped lang="less">
:deep(.df-btn) {
  background: linear-gradient(106.36deg, #ffe1ae 0%, #f6d07e 94.4%);
  //background-image: linear-gradient(106.36deg, #ffe1ae 0%, #f6d07e 94.4%);
  //background-color: #fff !important;
  &:hover {
    //background-color: rgba(255, 255, 255, 0.1) !important;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(106.36deg, #ffe1ae 0%, #f6d07e 94.4%);
  }
}
</style>
