import { getAdvertsExplore } from '@/api';
import type { AdvertsRequest, AdvertsResponse, CookieKeyTypes } from './interface';
import { AdCookieKeyPrefix } from './interface';
const ExpireTime = 30 * 24 * 60 * 60;
export const useAdvertising = () => {
  const getAdvertsApi = async (params: AdvertsRequest): Promise<AdvertsResponse[] | undefined> => {
    try {
      const { data } = await getAdvertsExplore(params);
      if (data?.value?.code === 0) {
        return data?.value?.data ?? [];
      }
    } catch (error) {
      console.warn('getAdverts error', error);
    }
  };
  const setAdCookie = (key: CookieKeyTypes, id: number | string, value: string) => {
    Cookie.set(`${AdCookieKeyPrefix[key]}_${id}`, value, {
      maxAge: ExpireTime,
    });
    return value;
  };
  const getAdCookie = (key: CookieKeyTypes, id: number | string) => {
    return Cookie.get(`${AdCookieKeyPrefix[key]}_${id}`);
  };
  return {
    getAdvertsApi,
    setAdCookie,
    getAdCookie,
  };
};
