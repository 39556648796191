type Fn = () => void;

/**
 * 判断页面登录状态的hooks
 * @params cb 当页面登出时，需要执行的方法
 * */
export function useLoginStatus(cb?: Fn) {
  // 全局通用的登录态判断属性
  const isLogin = ref(false);
  isLogin.value = !!useMiddleCookie('utoken').value;

  function removeLoginStatus() {
    isLogin.value = false;
  }

  let channel: BroadcastChannel;

  onMounted(() => {
    channel = new BroadcastChannel('login_status');

    channel.addEventListener('message', (evt) => {
      if (evt.data.data === 'logout') {
        removeLoginStatus();

        if (cb && typeof cb === 'function') {
          cb.apply(null);
        }
      }
    });
  });

  function broadcastLogout() {
    channel.postMessage({ data: 'logout' });
  }

  return {
    isLogin,
    broadcastLogout,
  };
}
