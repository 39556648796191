<template>
  <div class="user-icon">
    <div class="user-icon-img" :style="{ width: `${size}px`, height: `${size}px` }">
      <a-avatar
        v-if="isLogin"
        :size="size"
        :src="getOssProcessedImage(userInfoStore.myInfo.headpic.split('?')[0], 'm_fill,w_100,h_100', 'webp')"
        :draggable="false"
      />

      <img v-else class="img" :src="noLoginImg" @click="handleLogin" />
    </div>

    <!-- <a-dropdown :trigger="['hover']" overlay-class-name="login-dropdown">
      <div class="user-icon-img" :style="{ width: `${size}px`, height: `${size}px` }">
        <a-avatar
          v-if="isLogin"
          :size="size"
          :src="getOssProcessedImage(userInfoStore.myInfo.headpic.split('?')[0], 'm_fill,w_100,h_100', 'webp')"
          :draggable="false"
        />

        <img v-else class="img" :src="noLoginImg" @click="handleLogin" />
      </div>

      <template #overlay>
        <a-menu v-show="isLogin">
          <a-menu-item @click="handleLogout">
            <IconFont type="icon-logout-line" class="menu-icon" />
            <span class="menu-text">Logout</span>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown> -->
  </div>
</template>
<script setup lang="ts">
import noLoginImg from '@/assets/img/aimake/nologin.png';
import { useUserInfo } from '~/stores/user';

const { $client } = useNuxtApp();

type PropsType = {
  size: number;
};
defineProps<PropsType>();

// 声明事件，
type emitsType = {
  (event: 'logout'): void;
  (event: 'login'): void;
};

const emit = defineEmits<emitsType>();

const { isLogin } = useLoginStatus();
const userInfoStore = useUserInfo();

const login = () => {
  $client.openModal(() => {
    location.reload();
  });
};

// const logout = async () => {
//   try {
//     broadcastLogout();
//     await $client.logout();
//   } finally {
//     userInfoStore.resetUserInfo();
//     window.location.reload();
//   }
// };

// const handleLogout = async () => {
//   emit('logout');
//   logout();
// };

const handleLogin = async () => {
  emit('login');
  login();
};
</script>

<style lang="less" scoped>
.user-icon {
  user-select: none;
  margin-left: 16px;

  .user-icon-img {
    border-radius: 50%;
    overflow: hidden;

    .img {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
