import { defineStore } from 'pinia';

export enum Layouts {
  Default = 'default',
  Common = 'common',
}

const setup = () => {
  const layout = ref(Layouts.Default);
  const isHeaderHide = ref(false);
  const isShowNewProject = ref(false); // 显示创建入口弹窗
  const disableTab = ref(false); // 是否禁选tab
  const isChooseSale = ref(false); // 是否选择付费tab
  const isShowDraftEntry = ref(true); // 显示草稿入口

  const isShowMbDraftEntry = ref(true);
  const isShowMbEditorEntry = ref(false);

  const isShowMbNewProject = ref(false);
  const isShowMbDraft = ref(false);
  const isShowDownloadXcs = ref(false);
  const downloadXcsType = ref('');
  const remixBaseOnLink = ref('');
  function set(ly: Layouts) {
    layout.value = ly;
  }
  function setIsHeaderHide(val: boolean) {
    isHeaderHide.value = val;
  }
  // 打开/关闭 PC端新建弹窗
  function setIsShowNewProject(val: boolean, opts?: { disableTab?: boolean }) {
    isShowNewProject.value = val;
    disableTab.value = opts?.disableTab ?? disableTab.value;
  }
  function setIsShowMbNewProject(val: boolean) {
    isShowMbNewProject.value = val;
  }
  function setIsShowMbDraft(val: boolean) {
    isShowMbDraft.value = val;
  }
  function setIsShowDownloadXcs(val: boolean, type: string) {
    isShowDownloadXcs.value = val;
    downloadXcsType.value = type;
  }
  function setRemixBaseOnLink(link: string) {
    remixBaseOnLink.value = link;
  }
  function setIsChooseSale(val: boolean) {
    isChooseSale.value = val;
  }
  function openPcEditorEntryModal(sale: boolean) {
    isShowNewProject.value = true;
    isChooseSale.value = sale;
  }
  function openMbEditorEntryModal(opts?: { isShowMbDraft?: boolean }) {
    isShowMbEditorEntry.value = true;
    isShowMbDraftEntry.value = opts?.isShowMbDraft ?? isShowMbDraftEntry.value;
  }
  function closeMbEditorEntryModal() {
    isShowMbEditorEntry.value = false;
  }
  const setDraftEntry = (val: boolean) => {
    isShowDraftEntry.value = val;
  };
  const initStatus = () => {
    isShowDraftEntry.value = true;
    isChooseSale.value = false;
    disableTab.value = false;
    setRemixBaseOnLink('');
  };
  return {
    layout,
    set,
    isHeaderHide,
    isShowNewProject,
    disableTab,
    isShowDraftEntry,
    isShowMbDraftEntry,
    isShowMbEditorEntry,
    isShowMbNewProject,
    isShowMbDraft,
    isShowDownloadXcs,
    isChooseSale,
    downloadXcsType,
    remixBaseOnLink,
    setIsHeaderHide,
    setIsShowNewProject,
    setIsShowMbNewProject,
    setIsShowMbDraft,
    setIsShowDownloadXcs,
    setRemixBaseOnLink,
    setIsChooseSale,
    openPcEditorEntryModal,
    openMbEditorEntryModal,
    closeMbEditorEntryModal,
    setDraftEntry,
    initStatus,
  };
};

export const useLayoutStore = defineStore('layoutStore', setup);
