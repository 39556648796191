import { getUnreadNoticeListApi } from '@/api/notice';
import { defineStore } from 'pinia';

export const useNotice = defineStore('notice', {
  state: () => {
    return {
      // 铃铛的消息数
      count: 0,
      // 铃铛的消息列表
      list: [],
    };
  },
  actions: {
    async updateCount() {
      try {
        const { data } = await getUnreadNoticeListApi({
          current: 1,
          pageSize: 5,
        });
        if (data.value?.code === 0) {
          this.count = data.value.data.pagination.total;
          this.list = data.value.data.list || [];
        }
      } catch (error) {
        console.warn(error);
      }
    },
  },
});
