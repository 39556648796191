<template>
  <div ref="elRef" class="df-search-wrap">
    <span
      v-show="$route.path !== '/search-result'"
      class="mobile-btn bg-background-3 font-sans-bold text-level-1"
      @click="navigateTo('/search-result')"
    >
      <icon-font type="icon-search-line" class="cursor-pointer text-[24px]" />
    </span>
    <a-dropdown :open="isFocus" :arrow="false" :get-popup-container="(node: any) => node.parentNode">
      <a-input
        v-show="$route.path !== '/search-result'"
        v-model:value="keyword"
        size="large"
        placeholder="Search for..."
        class="df-search"
        @focus="isFocus = true"
        @blur="isFocus = false"
        @press-enter="pressEnter"
        @keydown="changeHighlight"
      >
        <template #prefix>
          <icon-font type="icon-search-line" class="text-[24px] text-[#333333]" />
        </template>
      </a-input>
      <template #overlay>
        <div
          class="rounded-[12px] bg-[#fff] pb-[24px] pt-[16px]"
          :style="{
            width: `${width}px`,
            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.06), 0 1px 20px 0 rgba(0, 0, 0, 0.1)',
          }"
        >
          <search-select
            :high-light-idx="highLightIdx"
            :keyword="keyword"
            @emit-suggest-data="emitSuggestData"
            @emit-change-keyword="chooseKeyword"
            @emit-report-toggle-data="reportToggleData"
          />
        </div>
      </template>
    </a-dropdown>
  </div>
</template>

<script setup lang="ts">
const isFocus = ref(false);

const width = ref<any>(400);
const marginRight = ref<any>(0);
const keyword = ref('');
const elRef = ref<HTMLElement>();

const getRect = () => {
  const searchDom = elRef.value;
  const searchDomStyle = searchDom?.getBoundingClientRect();
  if (searchDomStyle) {
    width.value = searchDomStyle.width < 300 ? 300 : searchDomStyle.width;
    marginRight.value = searchDomStyle.left;
  }
};

const highLightIdx = ref(-1);
const changeHighlight = (e: any) => {
  if (keyword.value) {
    if (e.keyCode === 40 && highLightIdx.value < suggestData.value.length - 1) {
      highLightIdx.value = highLightIdx.value + 1;
    }
    if (e.keyCode === 38 && highLightIdx.value > -1) {
      highLightIdx.value = highLightIdx.value - 1;
    }
  }
};
const suggestData = ref([]);
const emitSuggestData = (data: any) => {
  suggestData.value = data;
};
const chooseKeyword = (query: string) => {
  keyword.value = query;
  go2SearchResultPage();
};
const reset = () => {
  setTimeout(() => {
    keyword.value = '';
  }, 200);
};
const reportToggleData = () => {
  // showSearchSelect.value = bool;
};

let timer = null as any;
const handleWindowResize = () => {
  if (timer !== null) {
    clearTimeout(timer);
  }
  timer = setTimeout(getRect, 200);
};
onMounted(() => {
  addEventListener('resize', handleWindowResize);

  nextTick(() => {
    getRect();
  });
});
onUnmounted(() => {
  window.removeEventListener('resize', handleWindowResize);
});

const pressEnter = () => {
  if (!keyword.value) {
    return;
  }
  if (highLightIdx.value > -1) {
    keyword.value = suggestData.value[highLightIdx.value] as any;
    highLightIdx.value = -1;
  }
  go2SearchResultPage();
};
const { sensorSearchClick } = useSensors();
const router = useRouter();
const go2SearchResultPage = () => {
  try {
    sensorSearchClick({
      keyword: keyword.value,
      entry: '头部',
    });
  } finally {
    router.push(`/search-result?q=${encodeURIComponent(keyword.value)}`);
  }
  reset();
};
</script>

<style scoped lang="less">
.df-search-wrap {
  @media (min-width: 1300px) {
    max-width: 400px;
  }
  @media (max-width: 1299px) {
    max-width: 40px;
  }

  .df-search {
    border-radius: 39px;
    border-color: #f0f1f5;
    background: #f0f1f5;
    box-shadow: none;

    :deep(.ant-input) {
      background: #f0f1f5;
    }
    @media (max-width: 1299px) {
      display: none;
    }
  }
  .mobile-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;

    @media (min-width: 1300px) {
      display: none;
    }
  }
}
</style>
