<template>
  <div ref="elRef" :class="[`df-menu-item`, $attrs.class ?? '']" @mouseenter.stop="onMouseover" @click.stop="onToggle">
    <div v-if="!isNil(slots.icon)">
      <slot name="icon"></slot>
    </div>
    <a :href="to" class="title" @click.prevent="">
      <slot>{{ title || name }}</slot>
    </a>
    <template v-if="!isNil(slots.detail)">
      <div class="ml-1 inline-flex h-4 w-4">
        <ClientOnly>
          <icon-font type="icon-arrow-down" class="w-4 text-base" />
        </ClientOnly>
      </div>
      <Content />
    </template>
  </div>
</template>

<script setup lang="ts">
import { isNil } from 'lodash-es';
import { renderSlot, type VNode } from 'vue';
import { DF_MENU_CTX_KEY, type DfMenuCtx, type DfMenuItemInstance } from './df-menu';

const props = defineProps({
  to: { type: String, default: '' },
  name: { type: String, default: '' },
  title: { type: String, default: '' },
});

const slots = defineSlots<{
  default: () => VNode[];
  icon: () => VNode[];
  detail: () => VNode[];
}>();

const elRef = shallowRef<HTMLElement>();
const item = shallowReactive<DfMenuItemInstance>({
  get el() {
    return getComponentRootEl(elRef.value);
  },
});
const Content = () => {
  if (!isNil(slots.detail)) {
    item.content = renderSlot(slots, 'detail');
  }
  return [];
};

const onMouseover = () => {
  if (isInPC()) {
    ctx.enter(props.name);
  }
};
const onToggle = () => {
  if (ctx.openKey === props.name) {
    ctx.openKey = '';
  } else {
    ctx.enter(props.name);
  }
};

const ctx = inject(DF_MENU_CTX_KEY, null as unknown as DfMenuCtx);
ctx.put(props.name, item);
onBeforeUnmount(() => ctx.delete(props.name));
</script>

<style scoped lang="less">
.df-menu-item {
  display: inline-flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 26px;
  font-size: 0;
  cursor: pointer;
  transition: all 0.2s ease-in;

  a {
    font-size: 14px;
    font-style: normal;
    font-family: 'Open Sans SemiBold', sans-serif;
    line-height: 20px;
    color: #575757;
  }

  &:hover {
    background: #f0f1f5;
    a {
      color: #1a1a1a;
    }
  }
}
</style>
