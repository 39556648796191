<template>
  <div class="user-subs" :class="{ show: isShow }" @click="handleClick">
    <span class="text font-sans-semibold">Get Pro+</span>
  </div>
</template>
<script setup lang="ts">
import { SubscriptionPlan, SubscriptionStatus } from '~/components/subscription/constant';
import { useSubscription } from '~/stores/subscription';

const { isLogin } = useLoginStatus();
const subscription = useSubscription();

const isShow = computed(() => {
  if (!isLogin.value) {
    return false;
  }

  // 会员处于激活状态
  if (subscription.status === SubscriptionStatus.ACTIVE) {
    // 如果已经是Pro+用户，不显示
    if (subscription.plan === SubscriptionPlan.PRO_PLUS) {
      return false;
    }
  }

  return true;
});

const handleClick = () => {
  subscription.openPlansModal();
};
</script>

<style lang="less" scoped>
.user-subs {
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  height: 40px;
  margin-left: 16px;
  border-radius: 40px;
  background-image: linear-gradient(106deg, #ffe1ae 0%, #f6d07e 94.4%);
  cursor: pointer;
  transition: all 0.2s;
  display: none;

  &.show {
    display: flex;
  }

  &:hover {
    .text {
      color: #fff;
    }
    // box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  }

  .text {
    color: #a56409;
    font-size: 14px;
    line-height: 20px;
    transition: all 0.2s;
  }
}
</style>
