<template>
  <div id="layout" :class="{ 'pd': !isXcsEnterArtimindAi }">
    <Transition name="fade">
      <Header v-if="headerShow" />
    </Transition>

    <Transition name="fade">
      <AimakeHeader v-if="isShowArtimindAiHeader" />
    </Transition>

    <div class="layout-main">
      <slot></slot>
    </div>
    <Footer v-if="footerShow" />
    <ClientOnly>
      <LazyPreference />
      <LazySubscription />
      <LazyCommonCreateModal v-model:visible="layoutStore.isShowNewProject" :for-sale="layoutStore.isChooseSale" />
      <LazyCommonBack2Top />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { useLayoutStore } from '~/stores/layout';
import Footer from './footer.vue';
import Header from './header/index.vue';

const layoutStore = useLayoutStore();

const route = useRoute();
const headerShow = ref(true);
const footerShow = ref(true);
const pb = computed(() => (footerShow.value ? '32px' : '0'));

const isShowArtimindAiHeader = computed(() => {
  return route?.name === 'aimake-generate';
});

const isXcsEnterArtimindAi = computed(() => {
  return route?.name === 'aimake' && route?.query?.enter === 'xcs';
});

/**
 * 内容详情页不需要footer
 */
const initFooterShow = () => {
  const path = route.path;

  const regx = /\/(bundle|designs|xthings|howtos|inspiration|project)\/\d+/;
  if (path.includes('aimake')) {
    footerShow.value = false;
  } else if (path === '/creator/guide') {
    footerShow.value = false;
  } else {
    footerShow.value = !regx.test(path);
  }
};

/**
 * Artimind生成页不需要header
 */
const initHeaderShow = () => {
  // Artiminds AI生成页不需要显示header
  if (route?.name === 'aimake-generate') {
    headerShow.value = false;
    return;
  }

  // 从XCS编辑器入口进入aimake不需要header
  if (route?.name === 'aimake' && route?.query?.enter === 'xcs') {
    headerShow.value = false;
    return;
  }

  headerShow.value = true;
};

watch(
  () => route.path,
  () => {
    initFooterShow();
    initHeaderShow();
  },
  { immediate: true },
);
</script>

<style lang="less" scoped>
#layout {
  &.pd {
    padding-top: 64px;
    padding-bottom: v-bind(pb);
  }
  @media (max-width: 768px) {
    &.pd {
      padding-top: 66px;
    }
  }
  @media screen and (max-width: 992px) {
    &.pd {
      padding-bottom: 0;
    }
  }
}
.layout-main {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 96px);
  background: #f5f7fb;
  transition: filter 0.2s;
  @media screen and (max-width: 992px) {
    min-height: calc(100vh - 66px);
  }
}
</style>
