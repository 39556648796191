<template>
  <div id="mobile-header" class="mobile-header">
    <div class="mobile-header-wrap px-[20px]">
      <div class="mb-collapse-icon relative">
        <a-dropdown
          v-model:open="menuVisible"
          :trigger="['click']"
          :align="{ points: ['tl', 'bl'], offset: [-20, 22], overflow: { adjustX: false, adjustY: false } }"
          :get-popup-container="(n: any) => n.parentNode"
        >
          <a-badge :dot="noticeStore.count > 0">
            <icon-font type="icon-Navigation-line" class="cursor-pointer text-2xl text-text-primary" />
          </a-badge>
          <template #overlay>
            <div class="dropdown-menu">
              <div v-if="isLogin" class="user-center">
                <div class="info">
                  <ClientOnly>
                    <a-avatar :size="48" :src="getOssProcessedImage(userInfoStore.myInfo.headpic.split('?')[0], 'm_fill,w_60,h_60', 'webp')" />
                  </ClientOnly>
                  <p class="one-line-ellipsis">
                    {{ userInfoStore.myInfo.name }}
                  </p>
                </div>
                <NuxtLink to="/notice-center" class="uc-btn" @click="menuVisible = false">
                  <icon-font type="icon-message-line" />
                  Notification
                  <a-badge :count="noticeStore.count" :style="{ marginLeft: '8px' }" />
                </NuxtLink>
                <NuxtLink to="/rewards" class="uc-btn" @click="menuVisible = false">
                  <icon-font type="icon-coin-line" />
                  My Points
                  <span class="point">{{ userInfoStore.shopifyUserInfo.points || 0 }}&nbsp;Points</span>
                </NuxtLink>
                <NuxtLink to="/user-center?active=projects" class="uc-btn" @click="menuVisible = false">
                  <icon-font type="icon-planet-line" />
                  My Space
                </NuxtLink>
                <NuxtLink to="/creator/center/dashboard" class="uc-btn" @click="handleCreatorCenterClick">
                  <icon-font type="icon-a-CreatorCenter-line" />
                  Creator Center
                </NuxtLink>
                <a v-if="showSettings" class="uc-btn" @click="handleSetAccount">
                  <icon-font type="icon-settings-line" />
                  Settings
                </a>
              </div>
              <div class="bg"></div>
              <div @click.stop="handleSubmenuClick">
                <a-collapse v-model:activeKey="activeKey" ghost expand-icon-position="end" accordion>
                  <template #expandIcon="slotProps">
                    <icon-font type="icon-arrow-down" :rotate="slotProps?.isActive ? -180 : 0" />
                  </template>
                  <a-collapse-panel
                    v-for="seriesItem in xsMenus"
                    :key="seriesItem.key"
                    :show-arrow="Boolean(seriesItem.children && seriesItem.children.length > 0)"
                  >
                    <template #header>
                      <div v-if="seriesItem.children" class="header-link">
                        {{ seriesItem.title }}
                      </div>
                      <NuxtLink
                        v-if="!seriesItem.children"
                        data-type="link"
                        :to="`${seriesItem.path}`"
                        :class="['header-link', formatActive(seriesItem.path) ? 'activeRoute' : '']"
                      >
                        {{ seriesItem.title }}
                      </NuxtLink>
                    </template>
                    <div
                      v-for="subMenu in seriesItem.children"
                      :key="subMenu.key"
                      :class="['title-link', formatActive(subMenu.path) ? 'activeRoute' : '']"
                    >
                      <NuxtLink data-type="link" :to="`${subMenu.path}`">
                        {{ subMenu.title }}
                      </NuxtLink>
                    </div>
                  </a-collapse-panel>
                </a-collapse>
              </div>
              <a-button v-if="!isInMobileXcs" type="primary" class="action-btn subscription" @click="onUpgradePlan">Upgrade plan</a-button>
              <a-button v-if="!isLogin" class="action-btn" type="primary" @click="login">Login</a-button>
              <a-button v-if="isLogin" class="action-btn" @click="logout">
                <icon-font type="icon-logout-box-line" />
                Logout
              </a-button>
            </div>
          </template>
        </a-dropdown>
      </div>
      <NuxtLink to="/" class="absolute left-1/2 w-[153px] -translate-x-1/2">
        <Logo class="w-full" />
      </NuxtLink>
      <ClientOnly>
        <div v-if="!menuVisible" class="space-x-4">
          <icon-font v-if="currentPath !== '/search-result'" type="icon-search-line" class="text-2xl text-text-primary" @click="search" />
          <icon-font type="icon-a-ShareCreationh5" class="text-2xl text-text-primary" @click="check" />
        </div>
      </ClientOnly>
    </div>
    <ClientOnly>
      <LazyReleaseEntryModal v-model:visible="layoutStore.isShowMbEditorEntry" />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { useNotice } from '@/stores/notice';
import { useUserInfo } from '@/stores/user';
import { useSensors } from '~/composables';
import { useLayoutStore } from '~/stores/layout';
import Logo from './Logo.vue';

const { $client } = useNuxtApp();
const { isLogin, broadcastLogout } = useLoginStatus();

type MenuItem = {
  title: string;
  key: string;
  path: string;
  children?: MenuItem[];
};
const xsMenus = ref<MenuItem[]>([
  {
    title: 'Projects',
    key: 'project',
    path: '/',
  },
  {
    title: 'Designs',
    key: 'design',
    path: '/all?types=designs',
  },
  {
    title: 'Premium',
    key: 'premium',
    path: '/premium',
  },
  {
    title: 'Forum',
    key: 'forum',
    path: '/discussion',
  },
  /*   {
    title: 'Contests',
    key: 'contest',
    path: '/contest',
  }, */
]);

const menuVisible = ref(false);
const handleSubmenuClick = (e: any) => {
  if (e.target.dataset.type === 'link') {
    menuVisible.value = false;
  }
};

const activeKey = ref(['explore']);
const router = useRouter();
const route = useRoute();
const formatActive = (key: string) => {
  const currentRoute = router.currentRoute.value.path;
  return currentRoute.indexOf(key) > -1;
};
const currentPath = ref(route.path);

const search = () => {
  window.open(`/search-result`);
};

const login = () => {
  menuVisible.value = false;
  $client.openModal(() => {
    location.reload();
  });
};
const logout = async () => {
  menuVisible.value = false;
  try {
    broadcastLogout();
    await $client.logout();
  } finally {
    userInfoStore.resetUserInfo();

    const { sensors }: any = useSensors();
    sensors.logout();
    // 如果在个人中心退出登录自动跳转首页
    if (route.path === '/user-center') {
      window.location.href = '/';
    }
    window.location.reload();
  }
};

const layoutStore = useLayoutStore();

const check = () => {
  if (isLogin.value) {
    if (checkIsAtivity()) {
      layoutStore.setIsShowMbNewProject(true);
    } else {
      layoutStore.openMbEditorEntryModal();
    }
    return;
  }
  $client.openModal(() => {
    location.reload();
  });
};
const userInfoStore = useUserInfo();

const noticeStore = useNotice();

const handleSetAccount = () => {
  const isLogin = !!useMiddleCookie('utoken').value;
  if (!isLogin) {
    login();
    return;
  }
  const url = $client.getUrl('profile/settings');
  createAlink(url, '_blank');
};

const { sensorCreatorCenterEntryClick } = useSensors();
const handleCreatorCenterClick = () => {
  sensorCreatorCenterEntryClick();
  menuVisible.value = false;
};

const isInMobileXcs = call(() => {
  const isInMobileXcs = ref(false);
  onMounted(() => {
    isInMobileXcs.value = isInMobileApp();
  });
  return isInMobileXcs;
});
const onUpgradePlan = () => {
  menuVisible.value = false;
  navigateTo('/subscription/pricing');
};

const showSettings = ref(true);
onMounted(() => {
  nextTick(() => {
    showSettings.value = !(isElectronXcs() || isAppWebkit()); // 在电脑xcs、手机/iPad中不显示设置按钮
  });
});
</script>

<style lang="less" scoped>
.mobile-header {
  display: none;

  @media (max-width: 992px) {
    display: block;
  }
}
.mobile-header-wrap {
  width: 100%;
  height: 66px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-menu {
  z-index: 10;
  width: 100vw;
  max-height: calc(100vh - 66px);
  overflow: auto;
  overscroll-behavior: contain;
  background-color: #fff;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  .active {
    background: #f4f4f4;
  }
  .title-link {
    height: 48px;
    line-height: 48px;
    padding: 0 40px;
    font-family: 'Open Sans SemiBold', sans-serif;
    a {
      color: #212121;
      font-size: 14px;
      font-family: 'Open Sans SemiBold', sans-serif;
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    &:hover {
      background-color: #eee;
    }
  }
  .header-link {
    color: #212121;
    font-family: 'Open Sans SemiBold';
    font-size: 16px;
  }
  .activeRoute {
    color: #212121 !important;
  }
  :deep(.ant-collapse-item-active) {
    .header-link {
      color: #999;
    }
  }
  :deep(.ant-collapse-no-arrow .ant-collapse-header) {
    &:hover {
      background-color: #eee;
    }
    .ant-collapse-header-text {
      width: 100%;
      height: 100%;
    }
    .header-link {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #212121;
    }
  }
  .user-center {
    padding-top: 20px;
    .info {
      background: #fff;
      height: 56px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      margin-bottom: 8px;
      .ant-avatar {
        margin-right: 12px;
      }
      p {
        color: #212121;

        font-size: 18px;
        font-family: 'Open Sans SemiBold', sans-serif;
        margin: 0;
        width: calc(~'100% - 60px');
      }
    }
    .uc-btn {
      position: relative;
      padding: 0 20px;
      display: flex;
      align-items: center;
      height: 52px;
      line-height: 52px;
      color: #000;
      font-family: 'Open Sans SemiBold';
      font-size: 16px;

      .anticon {
        font-size: 24px;
        margin-right: 8px;
      }
      .point {
        position: absolute;
        right: 20px;
        color: theme('colors.text.tertiary');
        font-family: Arial;
        font-size: 14px;
        font-weight: 400;
      }
      :deep(.ant-badge-count) {
        border-radius: 15px;
      }
    }
  }
  .bg {
    width: 100%;
    height: 20px;
    background-color: #f4f4f4;
  }
}
:deep(.ant-collapse-icon-position-right) {
  > .ant-collapse-item > .ant-collapse-header {
    padding-left: 20px;
  }
}

:deep(.ant-collapse) {
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 0 20px;
      align-items: center;
      height: 48px;
      .ant-collapse-arrow {
        font-size: 24px;
        color: #999;
      }
    }
  }
}

:deep(.ant-collapse-ghost) > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
  background: #f8f8f8;
}
.action-btn {
  margin: 20px;
  width: calc(~'100% - 40px');
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  .anticon {
    font-size: 20px;
  }

  &.subscription {
    color: #a56409 !important;
    background: linear-gradient(106.36deg, #ffe1ae 0%, #f6d07e 94.4%);
  }
}
//:deep(.ant-badge-dot) {
//  box-shadow: none;
//  width: 8px;
//  height: 8px;
//  right: 0;
//}
</style>
<style lang="less">
.ant-dropdown {
  z-index: 2000;
}
</style>
