<template>
  <div ref="elRef" class="df-menu">
    <div ref="wrapRef" class="flex flex-nowrap">
      <slot></slot>
    </div>
    <Transition name="fade">
      <div
        v-show="!isNil(items[openKey]?.content)"
        class="df-menu__drawer"
        :style="{
          top: `${top}px`,
          left: '0',
          height: `calc(100vh - ${top}px)`,
        }"
      >
        <div class="df-menu__mask" :style="{ top: `${offset}px`, height: `calc(100% - ${offset}px)` }"></div>
        <div ref="patchRef" class="df-menu__patch absolute w-full" :style="{ top: '0', height: `${offset}px` }"></div>
        <div ref="itemContentRef" class="df-menu__item-content" :style="{ top: `${offset}px` }">
          <ItemsContent />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { isNil } from 'lodash-es';
import { vShow, withDirectives, type VNode } from 'vue';
import { DF_MENU_CTX_KEY, type DfMenuCtx, type DfMenuItemInstance } from './df-menu';

defineProps({
  offset: { type: Number, default: 0 },
});

const openKey = defineModel('openKey', {
  type: String,
  default: '',
});

const ItemsContent = () => {
  return Object.keys(items)
    .filter((name) => !isNil(items[name]?.content))
    .map((name) => {
      return withDirectives(h('div', {}, items[name]?.content as VNode), [[vShow, openKey.value === name]]);
    });
};

const mounted = ref(false);
onMounted(() => {
  mounted.value = true;
});

const top = ref(0);
const left = ref(0);
const elRef = shallowRef<HTMLElement>();

const items = shallowReactive<Record<string, DfMenuItemInstance>>({});

const wrapRef = shallowRef<HTMLElement>();
const patchRef = shallowRef<HTMLElement>();
const itemContentRef = shallowRef<HTMLElement>();

const bodyRef = shallowRef<HTMLElement>();
onMounted(() => {
  bodyRef.value = document.body;
});

const enter = (name: string) => {
  if (openKey.value === name) {
    return;
  }
  openKey.value = name;
  if (!isNil(elRef.value)) {
    const rect = elRef.value.getBoundingClientRect();
    top.value = rect.bottom - 4;
    left.value = -rect.left;
  }
};
const leave = (ev: MouseEvent) => {
  const el = ev.target as HTMLElement;
  if (
    openKey.value.length === 0 ||
    patchRef.value === el ||
    wrapRef.value?.contains(el) ||
    itemContentRef.value?.contains(el) ||
    items[openKey.value]?.el.contains(el)
  ) {
    return;
  }
  openKey.value = '';
};
onMounted(() => {
  // document.addEventListener('click', leave);
  document.addEventListener('mouseover', leave);
  document.addEventListener('mouseleave', leave);
});
onBeforeUnmount(() => {
  // document.removeEventListener('click', leave);
  document.removeEventListener('mouseover', leave);
  document.removeEventListener('mouseleave', leave);
});

const route = useRoute();
watch(
  () => route.fullPath,
  () => {
    openKey.value = '';
  },
);
watch(
  () => openKey.value,
  () => {
    // console.log('openKey.value', openKey.value, isNil(items[openKey.value]?.content));
    if (!isNil(items[openKey.value]?.content)) {
      document.querySelector('.layout-main')?.classList.add('filter-blur');
    } else {
      document.querySelector('.layout-main')?.classList.remove('filter-blur');
    }
  },
);

const ctx: DfMenuCtx = {
  get openKey() {
    return openKey.value;
  },
  set openKey(key: string) {
    openKey.value = key;
  },
  put(key, item) {
    items[key] = item;
  },
  delete(key) {
    delete items[key];
  },
  enter,
  leave,
};
provide(DF_MENU_CTX_KEY, ctx);
</script>

<style scoped lang="less">
.df-menu {
  position: relative;
}
.df-menu__drawer {
  position: fixed;
  z-index: 999;
  width: 100vw;
  overflow: hidden;
  background: transparent;
}
.df-menu__item-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 40px 24px;
  background: #fff;
}
.df-menu__mask {
  position: absolute;
  left: 0;
  width: 100%;
  background: #000;
  opacity: 0.4;
}
.df-menu__patch {
  background: transparent;
}
</style>
