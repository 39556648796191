// 生成图片方式对应的方法名字典配置，用于前端和后台进行映射逻辑
export const generateMethodMap = {
  laserart: 'LaserArt', // 激光艺术
  vectorgraphics: 'VectorArt', // 矢量
  imageTextMix: 'ImageTextMix', // 图文混排
  artword: 'CreativeText', // 艺术字
  handpainted: 'FaceSketch', // 肖像线稿
  relief: 'Relief', // 浮雕
  artimage: 'Artistic', // 艺术图像 MJ使用，暂不支持
  screenart: 'ScreenPrintArt', // 丝网
};

// 生成图片方式对应页面文案的字典配置, 用于页面展示
export const generateMethodTextMap = {
  [generateMethodMap.relief]: 'Embossment',
  [generateMethodMap.handpainted]: 'Face sketch',
};

import FaceMethodIcon from '@/assets/img/aimake/faceMethod.png';
import ReliefMethodIcon from '@/assets/img/aimake/reliefMethod.png';

// 首页生成工具列表
export const homeMethodList = [
  {
    icon: FaceMethodIcon,
    name: generateMethodTextMap[generateMethodMap.handpainted],
    key: generateMethodMap.handpainted,
    desc: 'Converting portraits into face sketches',
  },
  {
    icon: ReliefMethodIcon,
    name: generateMethodTextMap[generateMethodMap.relief],
    key: generateMethodMap.relief,
    desc: 'Generating a depth map for Embossment processing',
    label: 'For F1 Ultra',
  },
];

// 艺术图像3d
import styleA from '@/assets/img/aimake/styleA.png';
import styleB from '@/assets/img/aimake/styleB.png';

export const homePopularList = [
  {
    name: 'Creative logo1',
    name_zh: '创意logo',
    img_a: styleA,
    img_b: styleB,
    style: 'Creative logo',
    scene: generateMethodMap.imageTextMix,
    type: 'Logo',
  },
  {
    name: 'Creative logo2',
    name_zh: '创意logo',
    img_a: styleA,
    img_b: styleB,
    style: 'Creative logo',
    scene: generateMethodMap.imageTextMix,
  },
  {
    name: 'Creative logo3',
    name_zh: '创意logo',
    img_a: styleA,
    img_b: styleB,
    style: 'Creative logo',
    scene: generateMethodMap.imageTextMix,
  },
  {
    name: 'Creative logo4',
    name_zh: '创意logo',
    img_a: styleA,
    img_b: styleB,
    style: 'Creative logo',
    scene: generateMethodMap.imageTextMix,
  },
  {
    name: 'Creative logo5',
    name_zh: '创意logo',
    img_a: styleA,
    img_b: styleB,
    style: 'Creative logo',
    scene: generateMethodMap.imageTextMix,
    label: 'For F1 Ultra',
  },
];

// 历史记录失败状态文案映射
export const historyErrorTipsMap = {
  '1000': 'Sorry. The image generation failed due to the lack of facial recognition.',
  'server': 'Sorry. The image generation failed due to server exceptions.',
} as any;

import emptyErrorImg from '@/assets/img/aimake/empty_error.png';
import loginErrorImg from '@/assets/img/aimake/login_error.png';
import networkErrorImg from '@/assets/img/aimake/network_error.png';
import serverErrorImg from '@/assets/img/aimake/server_error.png';

// 异常页面的场景配置映射
export const errorPageSceneConfigMap = {
  // 网络异常
  network: {
    img: networkErrorImg,
    imgSize: { w: 100, h: 100 },
    desc: ['Network abnormality, please check the network connection'],
    button: 'Refresh',
  },
  // 服务异常
  server: {
    img: serverErrorImg,
    imgSize: { w: 150, h: 100 },
    desc: ['The server is out of service, please try again later.'],
    button: 'Reload',
  },
  // 未登录
  login: {
    img: loginErrorImg,
    imgSize: { w: 150, h: 100 },
    desc: ['You can view your image generation history after logging in.'],
  },
  // 历史记录为空
  historyEmpry: {
    img: emptyErrorImg,
    imgSize: { w: 100, h: 100 },
    desc: ["You haven't used the image generation function before.", "Click 'Generate' to have a try."],
  },
} as any;

// 历史记录状态映射
export const historyStatusMap = {
  line: 1, // 排队中
  pending: 2, // 生成中
  success: 3, // 成功
  fail: 4, // 失败
};

// 教程文档映射
export const helpIconUrlMap = {
  [generateMethodMap.artimage]: 'https://support.xtool.com/article/869',
  [generateMethodMap.artword]: 'https://support.xtool.com/article/1678',
  [generateMethodMap.imageTextMix]: 'https://support.xtool.com/article/1679',
  [generateMethodMap.handpainted]: 'https://support.xtool.com/article/1309?from=search&q=AImake%20face',
  'def': 'https://support.xtool.com/article/1679',
};
