<template>
  <div class="user-credits">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <mask id="mask0_643_17993" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_643_17993)">
        <path
          d="M10 17C6.13401 17 3 13.866 3 9.99996C3 6.134 6.13401 3 10 3C13.866 3 17 6.134
           17 9.99996C17 13.866 13.866 17 10 17ZM10.7071 7.73725C10.3166 7.34673
           9.68343 7.34672 9.2929 7.73724L7.73726 9.29286C7.34674 9.68338 7.34673
           10.3165 7.73725 10.7071L9.29289 12.2627C9.68342 12.6533 10.3166 12.6533 10.7071
           12.2627L12.2627 10.7071C12.6532 10.3165 12.6532 9.68338 12.2627 9.29286L10.7071 7.73725Z"
          fill="url(#paint0_linear_643_17993)"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_643_17993" x1="10" y1="3" x2="10" y2="17" gradientUnits="userSpaceOnUse">
          <stop stop-color="#F0C38B" />
          <stop offset="1" stop-color="#F2AB51" />
        </linearGradient>
      </defs>
    </svg>

    <span v-show="isInit" class="text">{{ artimindAiStore.credits || 0 }}</span>
  </div>
</template>
<script setup lang="ts">
import { useArtimindAiStore } from '@/stores/aimake';
import { useFeedbackModal } from '~/stores/subscription';

const { isLogin } = useLoginStatus();
const feedbackModal = useFeedbackModal();
const artimindAiStore = useArtimindAiStore();
const isInit = ref(false);

watch(
  () => feedbackModal.status,
  (val) => {
    if (['succeed', 'failed'].includes(val)) {
      isLogin.value && artimindAiStore.getCredits();
    }
  },
  {
    immediate: true,
  },
);

onMounted(async () => {
  isLogin.value && (await artimindAiStore.getCredits());
  isInit.value = true;
});
</script>

<style lang="less" scoped>
.user-credits {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  margin-left: 16px;
  min-width: 62px;

  .text {
    color: #575757;
    font-size: 14px;
  }
}
</style>
