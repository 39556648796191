import type { InjectionKey, VNode } from 'vue';

export const DF_MENU_CTX_KEY: InjectionKey<DfMenuCtx> = Symbol('DF_MENU_CTX_KEY');

export interface DfMenuCtx {
  openKey: string;
  put(key: string, item: DfMenuItemInstance): void;
  delete(key: string): void;
  enter(key: string): void;
  leave(ev: MouseEvent): void;
}

export interface DfMenuItemInstance {
  readonly el: HTMLElement;
  content?: VNode;
}
