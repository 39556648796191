export interface AdvertsResponse {
  word: string; // 文案
  pcImage: string; // PC广告图
  mobileImage: string; // mobile广告图
  link: string; // 跳转链接
  type: AdvertType; // 广告类型
}

export type AdvertType =
  | 'machine'
  | 'material'
  | 'machineContent'
  | 'materialContent'
  | 'collectTop'
  | 'collectFlow'
  | 'search'; // machine-机器页, material-耗材页, machineContent-机器内容页, materialContent-耗材内容页, collectTop-合集顶部, collectFlow-合集信息流, search-搜索词

export interface AdvertsRequest {
  type?: AdvertType[]; // 广告类型
  collectId?: number | string; // 合集id
  id?: number | undefined; // 广告id
}

// 广告cookie key前缀
export const AdCookieKeyPrefix = {
  BLOCK: 'XTOOL_COMMUNITY_BLOCK_AD',
  COLLECTION_BANNER: 'XTOOL_COMMUNITY_COLLECTION_BANNER_AD',
  COLLECTION_ITEM: 'XTOOL_COMMUNITY_COLLECTION_ITEM_AD',
};

export type CookieKeyTypes = keyof typeof AdCookieKeyPrefix;
