// 处理链接
export function useSiteLink() {
  const router = useRouter();
  const token = useMiddleCookie('utoken');
  const siteUrl = import.meta.env.VITE_PUBLIC_SITE_URL;
  const passportUrl = import.meta.env.VITE_PASSPORT_SITE_URL;

  // mobile webView iframe
  const isInAppWebkit = () => {
    return (window as any)?.LinkNative || (window as any)?.webkit?.messageHandlers?.LinkNative;
  };

  // (iPad) && (not mobile webView iframe)
  const isInIpad = () => {
    return !isInAppWebkit() && (navigator?.userAgent?.match(/(iPad)/) || (navigator?.platform === 'MacIntel' && navigator?.maxTouchPoints > 1));
  };

  // Electron
  const isInElectron = () => {
    return typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0;
  };

  // 获取支付链接
  const getPayLinkUrl = (id: number) => {
    return {
      successUrl: `${passportUrl}/en/authcb?ut=${token.value}&r=${siteUrl}/pay/status/${id}`,
      cancelUrl: globalThis.location.href,
    };
  };

  const isInXcs = computed(() => isInElectron() || isInAppWebkit() || isInIpad());

  return {
    isInXcs,
    getPayLinkUrl,
    siteUrl,
    isInAppWebkit,
    isInIpad,
    isInElectron,
    goBack: () => router.go(-1),
  };
}
