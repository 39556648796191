<template>
  <div class="df-user-wrap">
    <df-btn v-if="!isLogin" type="default" @click="login">Login</df-btn>
    <ClientOnly>
      <a-dropdown
        v-if="isLogin"
        v-model:open="isAvatarPopoverVisible"
        :arrow="false"
        :trigger="'hover'"
        :placement="'bottom'"
        :get-popup-container="(node: any) => node.parentNode"
      >
        <a-avatar
          :src="getOssProcessedImage(userInfoStore.myInfo.headpic.split('?')[0], 'm_fill,w_100,h_100', 'webp')"
          :size="40"
          :style="{ cursor: 'pointer' }"
          :alt="`${userInfoStore.myInfo.name}-DesignFind`"
        />
        <template #overlay>
          <div class="user-pop" @click="onAvatarPopoverClick">
            <NuxtLink to="/rewards">
              <icon-font type="icon-coin-line" />
              {{ userInfoStore.shopifyUserInfo.points || 0 }}
            </NuxtLink>
            <NuxtLink to="/user-center?active=projects">
              <icon-font type="icon-planet-line" />
              My Space
            </NuxtLink>
            <NuxtLink to="/creator/center/dashboard" @click="sensorCreatorCenterEntryClick">
              <icon-font type="icon-a-CreatorCenter-line" />
              Creator Center
            </NuxtLink>
            <a v-if="showSettings" @click="handleSetAccount">
              <icon-font type="icon-settings-line" />
              Settings
            </a>
            <a @click="logout">
              <icon-font type="icon-logout-box-line" />
              Logout
            </a>
          </div>
        </template>
      </a-dropdown>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { useSensors } from '~/composables';
import { useUserInfo } from '~/stores/user';
import { isAppWebkit, isElectronXcs } from '~/utils/system';

const route = useRoute();

const { isLogin, broadcastLogout } = useLoginStatus();

const { $client } = useNuxtApp();
const login = () => {
  $client.openModal(() => {
    location.reload();
  });
};
const logout = async () => {
  try {
    broadcastLogout();
    await $client.logout();
  } finally {
    userInfoStore.resetUserInfo();

    const { sensors }: any = useSensors();
    sensors.logout();
    // 如果在个人中心退出登录自动跳转首页
    if (route.path === '/user-center') {
      window.location.href = '/';
    }
    window.location.reload();
  }
};

const userInfoStore = useUserInfo();

const handleSetAccount = () => {
  const isLogin = !!useMiddleCookie('utoken').value;
  if (!isLogin) {
    login();
    return;
  }
  const url = $client.getUrl('profile/settings');
  createAlink(url, '_blank');
};

// 捕获点击事件，隐藏popover
const isAvatarPopoverVisible = ref(false);
function onAvatarPopoverClick() {
  isAvatarPopoverVisible.value = false;
}

const { sensorCreatorCenterEntryClick } = useSensors();

const userInfo = ref();
const { sensors }: any = useSensors();
// 处理设置神策用户属性
const handleSetUserSensors = () => {
  // 登录成功设置调用神策登录接口，将用户id与神策id关联。上线后替换xtool_community_id
  sensors.loginWithKey(import.meta.env.VITE_SENSORS_ID_KEY, userInfo.value.uid);

  // 设置用户属性，存在则覆盖
  sensors.setProfile({
    name: userInfo.value.userName,
    registered_email: userInfo.value.email,
    xtool_community_id: userInfo.value.uid,
    registered_time: userInfo.value.createTime,
  });
};

const showSettings = ref(true);
onMounted(() => {
  nextTick(() => {
    showSettings.value = !(isElectronXcs() || isAppWebkit()); // 在电脑xcs、手机/iPad中不显示设置按钮
  });
});

onMounted(async () => {
  if ($client.token) {
    nextTick(async () => {
      userInfo.value = await $client.userInfo();
      handleSetUserSensors();
    });
  }
});
</script>

<style scoped lang="less">
.user-pop {
  display: flex;
  flex-direction: column;
  width: 186px;
  padding: 2px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.06),
    0 1px 20px 0 rgba(0, 0, 0, 0.1);
  //overflow: hidden;

  a {
    display: inline-flex;
    align-items: center;
    padding: 7px 12px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #212121;

    &:hover {
      color: #1a1a1a;
      background: #f5f7fb;
    }

    &:first-child:hover {
      border-radius: 10px 10px 0 0;
    }

    &:last-child:hover {
      border-radius: 0 0 10px 10px;
    }

    .anticon {
      margin-right: 12px;
      font-size: 20px;
    }
  }
}
</style>
