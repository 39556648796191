<template>
  <div id="notice-icon" class="notice-message-list cursor-pointer">
    <a-popover
      v-model:open="noticeShow"
      :auto-adjust-overflow="false"
      placement="bottomRight"
      trigger="click"
      overlay-class-name="notice-message-list-popover"
      :get-popup-container="(triggerNode:any) => triggerNode.parentNode"
      @open-change="goMessage"
    >
      <a-badge class="custom-badge" :count="noticeStore.count" :overflow-count="99">
        <icon-font class="icon-message" type="icon-message-line" />
      </a-badge>
      <template #content>
        <a-config-provider>
          <template #renderEmpty>
            <a-empty :image="emptyImg" class="empty-img">
              <template #description>
                <span>No Message</span>
              </template>
            </a-empty>
          </template>
          <a-list :data-source="noticeStore.list" :loading="isLoading" class="notice-message-list">
            <template #renderItem="{ item }">
              <a-list-item class="notice-message-list-item" @click="handleNoticeClick(item)">
                <a-space direction="vertical" style="width: 100%">
                  <div class="notice-list-item-wrap-1">
                    <author-user-img
                      class="user-img"
                      img-size="32px"
                      :user-id="item.createdBy"
                      :user-img="item.createdByHeadpic"
                      :user-name="item.createdByName"
                    />
                    <span class="time">
                      {{ moment(item.createdAt * 1000).format('MMM DD,YYYY') }}
                    </span>
                  </div>
                  <div class="notice-list-item-wrap-2 two-line-ellipsis">
                    <span v-if="item.title" class="title">
                      {{ item.title }}
                    </span>
                    <span class="content">
                      {{ item.content }}
                    </span>
                  </div>
                </a-space>
              </a-list-item>
            </template>
            <template #loadMore>
              <div class="load-more-wrap" @click="handleJumpAllMessage">All Messages</div>
            </template>
          </a-list>
        </a-config-provider>
      </template>
    </a-popover>
  </div>
</template>

<script lang="ts" setup>
import { tagNoticeReadApi } from '@/api';
import emptyImg from '@/assets/img/no-data.png';
import { useNotice } from '@/stores/notice';
import moment from 'moment';
const siteUrl = import.meta.env.VITE_PUBLIC_SITE_URL;

const noticeShow = ref(false);
const noticeStore = useNotice();
const isLoading = ref(false);
const handleGetNoticeList = async () => {
  try {
    isLoading.value = true;
    await noticeStore.updateCount();
  } catch (error) {
    console.warn(error);
  } finally {
    isLoading.value = false;
  }
};
const handleCloseNotice = () => {
  noticeShow.value = false;
};
const handleJumpAllMessage = () => {
  handleCloseNotice();
  navigateTo({
    path: '/notice-center',
    query: {
      active: 'all',
    },
  });
};
const goMessage = () => {
  if (window.innerWidth < 767) {
    noticeShow.value = false;
    navigateTo({
      path: '/notice-center',
      query: {
        active: 'all',
      },
    });
  }
};

/**
 * 如果是在电脑端xcs，站内链接使用_self打开，站外链接使用_blank打开
 * 如果是在电脑浏览器，所有链接都使用_blank打开
 * 如果是在移动端，浏览器或xcs，所有链接都使用_self打开
 */
function makeTargetStat(item: any) {
  const innerWeb = item.link.startsWith(siteUrl) || item.link.includes('mblock.cc'); // 站内
  const { isInElectron, isInAppWebkit, isInIpad } = useSiteLink();
  const isMobile = isInAppWebkit() || isInIpad();
  if (isInElectron()) {
    return innerWeb ? '_self' : '_blank';
  } else {
    return isMobile ? '_self' : '_blank';
  }
}

const handleNoticeClick = async (item: any) => {
  try {
    isLoading.value = true;
    await tagNoticeReadApi({
      id: item.id,
      type: item.type,
    });
    noticeStore.updateCount();
    handleCloseNotice();
    const linkStyle = makeTargetStat(item) as any;
    navigateTo(item.link, {
      open: {
        target: linkStyle,
      },
    });
  } catch (error) {
    console.warn(error);
  } finally {
    isLoading.value = false;
  }
};
watch(
  () => noticeShow.value,
  (val: boolean) => {
    if (val) {
      handleGetNoticeList();
    }
  },
);
onMounted(() => {
  nextTick(() => {
    noticeStore.updateCount();
  });
});
</script>

<style lang="less">
.notice-message-list-popover {
  min-width: 300px;
  border-radius: 12px;
  .empty-img {
    .ant-empty-image {
      height: auto;
      img {
        width: 120px;
        height: 120px;
      }
    }
  }
  .ant-popover-inner {
    border-radius: 12px;
    min-width: 360px;
    padding: 0;
  }
  .ant-list-items {
    padding: 16px 20px 0 16px;
  }
  .notice-message-list {
    position: relative;
    &-item {
      cursor: pointer;
      width: 430px;
      word-break: break-word;
      &:last-of-type {
        border-bottom: none !important;
      }
      .notice-list-item-wrap-1 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .time {
          color: #999;
        }
      }
      .notice-list-item-wrap-2 {
        padding-left: 40px;
        margin-bottom: 4px;
        .title {
          margin-right: 4px;
        }
        .content {
          color: #222;
          font-family: 'Open Sans SemiBold';
        }
      }
    }
    .load-more-wrap {
      // position: absolute;
      cursor: pointer;
      font-size: 16px;
      color: #212121;
      text-align: center;
      height: 50px;
      line-height: 50px;
      border-top: 1px solid rgba(0, 0, 0, 0.16);
      color: theme('colors.text.secondary');
      &:hover {
        color: theme('colors.text.primary');
      }
    }
  }

  .ant-popover-arrow {
    display: none;
  }
}

.custom-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  font-size: 40px;
  background: #f0f1f5;
  .ant-badge-count {
    box-shadow: none;
  }
}
.notice-message-list {
  .icon-message {
    font-size: 26px;
    color: #1a1a1a;
  }
}
:deep(.ant-popover-inner) {
  border-radius: 12px;
  min-width: 360px;
}
</style>
