import { Methods, request, REQUEST_SEVER } from '@/utils/request';
import { defineCommonApi } from '~/utils/fetch/define-api';

// 获取用户当前算力值
export const getUserCurCredits = defineCommonApi({
  url: '/ai/v1/credit/getBalance',
  method: HttpMethod.GET,
  respTy: RespTy.Json<any>(),
});

// 查询历史任务
export const getHistory = defineCommonApi({
  url: '/ai/v3/artimind/task/status',
  method: HttpMethod.GET,
  respTy: RespTy.Json<any>(),
});

// 删除历史任务
export const deleteHistory = (data: { id: string }) => {
  return request[REQUEST_SEVER.COMMON]({
    url: `/ai/v2/artimind/task/${data.id}`,
    method: Methods.DELETE,
    data,
  });
};

// 查询历史任务状态
export const getHistoryStatus = (data: { task_ids: string[] }) => {
  return request[REQUEST_SEVER.COMMON]({
    url: `/ai/v3/artimind/task/status`,
    method: Methods.POST,
    data,
  });
};

// 提交生成
export const submitGenerate = (data: any) => {
  return request[REQUEST_SEVER.COMMON]({
    url: `/ai/v3/artimind/task/commit`,
    method: Methods.POST,
    data,
  });
};
