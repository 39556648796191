<template>
  <div class="main-content">
    <div class="main">
      <slot></slot>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style lang="less" scoped>
.main-content {
  min-width: 1200px;
}
.main {
  min-height: calc(100vh - 232px);
  background: #f5f7fb;
}
// 移动端适配 start
@media screen and (max-width: 767px) {
  .main-content {
    width: 100%;
    min-width: 375px;
  }
  .main {
    min-height: calc(100vh - 491px);
  }
}
// end
</style>
