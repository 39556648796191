<template>
  <div class="aimake-header">
    <div class="logo flex-r-c" @click="handleClickLogo">
      <IconFont class="icon" type="icon-arrow_left" size="20" />
      <img :src="ArtimindHeaderLogo" alt="logo" />
    </div>

    <div class="main">
      <Subs />
      <Credits v-show="isLogin" />
      <User :size="40" />
    </div>
  </div>
</template>

<script setup lang="ts">
import ArtimindHeaderLogo from '@/assets/img/aimake/logo.svg';
import { useRoute, useRouter } from 'vue-router';
import Credits from './Credits.vue';
import Subs from './Subs.vue';
import User from './User.vue';

const { isLogin } = useLoginStatus();
const router = useRouter();
const route = useRoute();

const handleClickLogo = () => {
  if (history?.state?.back?.includes('aimake')) {
    router.back();
  } else {
    router.push({
      name: 'aimake',
      query: {
        ...route.query,
      },
    });
  }
};
</script>

<style lang="less" scoped>
.aimake-header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background-color: #fff;
  padding: 0 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(26, 26, 26, 0.1);

  .logo {
    cursor: pointer;

    img {
      height: 40px;
    }

    .icon {
      font-size: 20px;
      margin-right: 8px;
    }
  }

  .main {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

// 移动端适配 start
@media screen and (max-width: 767px) {
}
// end
</style>
