import { generateMethodMap } from '@/config/aimake/common';

// 艺术图像3d
import styleA from '@/assets/img/aimake/styleA.png';
import styleB from '@/assets/img/aimake/styleB.png';

// 样式栏目映射
export const styleTypeMap = {
  vectorgraphics: 'For Vector Designing',
  threeD: 'For 3D Effect Engraving',
  logo: 'For Logo Designing',
  imageTextMix: 'For Text & Graphics Designing',
  blackWhite: 'For Monochrome Engraving',
  artword: 'For Typography Designing',
  color: 'For Colorful Printing',
  screenart: 'For Screen Printing',
} as any;

// style选择弹窗文案映射
export const styleSelectTextMap = {
  [styleTypeMap.vectorgraphics]: 'Vector',
  [styleTypeMap.threeD]: '3D engraving',
  [styleTypeMap.logo]: 'Logo',
  [styleTypeMap.imageTextMix]: 'Text & graphics',
  [styleTypeMap.blackWhite]: 'Monochrome',
  [styleTypeMap.artword]: 'Typography',
  [styleTypeMap.color]: 'Colorful printing',
  [styleTypeMap.screenart]: 'Screen printing',
} as any;

import styleImageTextMix2DSilhouetteImg from '@/assets/img/aimake/style/imageTextMix/2DSilhouette.jpg';
import styleImageTextMixClassicQuoteImg from '@/assets/img/aimake/style/imageTextMix/ClassicQuote.jpg';
import styleImageTextMixColoringBookImg from '@/assets/img/aimake/style/imageTextMix/ColoringBook.jpg';
import styleImageTextMixCreativeLogoImg from '@/assets/img/aimake/style/imageTextMix/CreativeLogo.jpg';
import styleImageTextMixCursiveScriptImg from '@/assets/img/aimake/style/imageTextMix/CursiveScript.jpg';
import styleImageTextMixCutoutLogoImg from '@/assets/img/aimake/style/imageTextMix/CutoutLogo.jpg';
import styleImageTextMixHandDrawnBadgeImg from '@/assets/img/aimake/style/imageTextMix/HandDrawnBadge.jpg';
import styleImageTextMixPencilSketchImg from '@/assets/img/aimake/style/imageTextMix/PencilSketch.jpg';
import styleImageTextMixPunkImg from '@/assets/img/aimake/style/imageTextMix/Punk.jpg';
import styleImageTextMixRetroPrintingImg from '@/assets/img/aimake/style/imageTextMix/RetroPrinting.jpg';
import styleImageTextMixSportsTeamLogoImg from '@/assets/img/aimake/style/imageTextMix/SportsTeamLogo.jpg';
import styleImageTextMixStreetArtImg from '@/assets/img/aimake/style/imageTextMix/StreetArt.jpg';

// 图文混排
export const imageTextMixStyleList = [
  {
    name: 'Creative logo',
    name_zh: '创意logo',
    key: 'Creative logo',
    img: styleImageTextMixCreativeLogoImg,
    img_a: styleA,
    img_b: styleB,
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.logo,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix',
    isPopular: true,
  },

  {
    name: 'Hand-drawn badge',
    name_zh: '手绘徽章',
    key: 'Hand-drawn badge',
    img: styleImageTextMixHandDrawnBadgeImg,
    img_a: styleA,
    img_b: styleB,
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.logo,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix',
    isPopular: true,
  },
  {
    name: 'Sports team logo',
    name_zh: '运动队徽章',
    key: 'Sports team logo',
    img: styleImageTextMixSportsTeamLogoImg,
    img_a: styleA,
    img_b: styleB,
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.logo,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix',
    isPopular: true,
  },
  {
    name: 'Cutout logo',
    name_zh: '镂空徽标',
    key: 'Cutout logo',
    img: styleImageTextMixCutoutLogoImg,
    img_a: styleA,
    img_b: styleB,
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.logo,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix2',
    isPopular: true,
  },

  {
    name: 'Retro printing',
    name_zh: '复古印刷',
    key: 'Retro printing',
    img: styleImageTextMixRetroPrintingImg,
    img_a: styleA,
    img_b: styleB,
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.logo,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix2',
    isPopular: true,
  },
  {
    name: 'Cursive script',
    name_zh: '复古草书',
    key: 'Cursive script',
    img: styleImageTextMixCursiveScriptImg,
    img_b: styleB,
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.logo,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix2',
  },

  {
    name: 'Punk',
    name_zh: '朋克',
    key: 'Punk',
    img: styleImageTextMixPunkImg,
    img_b: styleB,
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.logo,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix2',
  },

  {
    name: 'Pencil sketch',
    name_zh: '铅笔素描',
    key: 'Pencil sketch',
    img: styleImageTextMixPencilSketchImg,
    img_b: styleB,
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.logo,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix2',
  },

  {
    name: 'Classic quote',
    name_zh: '经典语录',
    key: 'Classic quote',
    img: styleImageTextMixClassicQuoteImg,
    img_b: styleB,
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.imageTextMix,
    advanced: false,
    logo_text: 'logoText2',
    prompts: 'imageTextMix2',
    logo_text_length: 40,
  },

  {
    name: 'Coloring book',
    name_zh: '上色卡',
    key: 'Coloring book',
    img: styleImageTextMixColoringBookImg,
    img_b: styleB,
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.imageTextMix,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix2',
  },

  {
    name: '2D silhouette',
    name_zh: '平面剪影',
    key: '2D silhouette',
    img: styleImageTextMix2DSilhouetteImg,
    img_b: styleB,
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.imageTextMix,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix2',
  },

  {
    name: 'Street art',
    name_zh: '街头艺术',
    key: 'Street art',
    img: styleImageTextMixStreetArtImg,
    img_b: styleB,
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.imageTextMix,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix2',
  },
];

import styleArtword3DImg from '@/assets/img/aimake/style/artword/3D.jpg';
import styleArtwordBadgeImg from '@/assets/img/aimake/style/artword/Badge.jpg';
import styleArtwordBlackWhiteStickerImg from '@/assets/img/aimake/style/artword/BlackWhiteSticker.jpg';
import styleArtwordEllipseImg from '@/assets/img/aimake/style/artword/Ellipse.jpg';
import styleArtwordFlameImg from '@/assets/img/aimake/style/artword/Flame.jpg';
import styleArtwordFloralTextImg from '@/assets/img/aimake/style/artword/FloralText.jpg';
import styleArtwordGraffitiTextImg from '@/assets/img/aimake/style/artword/GraffitiText.jpg';
import styleArtwordHandmadePostersImg from '@/assets/img/aimake/style/artword/HandmadePosters.jpg';
import styleArtwordInkWashImg from '@/assets/img/aimake/style/artword/InkWash.jpg';
import styleArtwordPencilTextureImg from '@/assets/img/aimake/style/artword/PencilTexture.jpg';
import styleArtwordPixelImg from '@/assets/img/aimake/style/artword/Pixel.jpg';
import styleArtwordPlanetImg from '@/assets/img/aimake/style/artword/Planet.jpg';
import styleArtwordRetroBlackWhiteImg from '@/assets/img/aimake/style/artword/RetroBlackWhite.jpg';
import styleArtwordRetroNobleImg from '@/assets/img/aimake/style/artword/RetroNoble.jpg';
import styleArtwordSplitMonogramImg from '@/assets/img/aimake/style/artword/SplitMonogram.jpg';

// 艺术字
export const artwordStyleList = [
  {
    name: 'Split monogram',
    name_zh: '分割字母',
    key: 'Split monogram',
    img: styleArtwordSplitMonogramImg,
    img_b: styleB,
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText',
    prompts_length: 15,
  },

  {
    name: 'Flame',
    name_zh: '激情火焰',
    key: 'Flame',
    img: styleArtwordFlameImg,
    img_b: styleB,
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText',
    prompts_length: 15,
  },

  {
    name: 'Ellipse',
    name_zh: '椭圆字体',
    key: 'Ellipse',
    img: styleArtwordEllipseImg,
    img_b: styleB,
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText',
    prompts_length: 15,
  },

  {
    name: 'Planet',
    name_zh: '星球',
    key: 'Planet',
    img: styleArtwordPlanetImg,
    img_b: styleB,
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText',
    prompts_length: 15,
  },

  {
    name: '3D',
    name_zh: '3D',
    key: '3D',
    img: styleArtword3DImg,
    img_b: styleB,
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText2',
    prompts_length: 40,
  },

  {
    name: 'Handmade poster',
    name_zh: '手工海报',
    key: 'Handmade poster',
    img: styleArtwordHandmadePostersImg,
    img_b: styleB,
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText2',
    prompts_length: 40,
  },

  {
    name: 'Retro & noble',
    name_zh: '复古贵族',
    key: 'Retro & noble',
    img: styleArtwordRetroNobleImg,
    img_b: styleB,
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText',
    prompts_length: 15,
  },

  {
    name: 'Pencil texture',
    name_zh: '铅笔纹理',
    key: 'Pencil texture',
    img: styleArtwordPencilTextureImg,
    img_b: styleB,
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText',
    prompts_length: 15,
  },

  {
    name: 'Graffiti text',
    name_zh: '涂鸦艺术字',
    key: 'Graffiti text',
    img: styleArtwordGraffitiTextImg,
    img_b: styleB,
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText2',
    prompts_length: 40,
  },

  {
    name: 'Floral text',
    name_zh: '鲜花艺术字',
    key: 'Floral text',
    img: styleArtwordFloralTextImg,
    img_b: styleB,
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText2',
    prompts_length: 40,
  },

  {
    name: 'Ink wash',
    name_zh: '水墨',
    key: 'Ink wash',
    img: styleArtwordInkWashImg,
    img_b: styleB,
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText',
    prompts_length: 15,
  },

  {
    name: 'Black & white sticker',
    name_zh: '喷漆贴纸',
    key: 'Black & white sticker',
    img: styleArtwordBlackWhiteStickerImg,
    img_b: styleB,
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText2',
    prompts_length: 40,
  },

  {
    name: 'Retro black & white',
    name_zh: '复古黑白',
    key: 'Retro black & white',
    img: styleArtwordRetroBlackWhiteImg,
    img_b: styleB,
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText2',
    prompts_length: 40,
  },

  {
    name: 'Badge',
    name_zh: '徽章',
    key: 'Badge',
    img: styleArtwordBadgeImg,
    img_b: styleB,
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText2',
    prompts_length: 40,
  },

  {
    name: 'Pixel',
    name_zh: '像素风格',
    key: 'Pixel',
    img: styleArtwordPixelImg,
    img_b: styleB,
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText2',
    prompts_length: 40,
  },
];

import styleVector2DGraphicImg from '@/assets/img/aimake/style/vector/2Dgraphics.jpg';
import styleVectorFibonacciImg from '@/assets/img/aimake/style/vector/Fibonacci.jpg';
import styleVectorGeometricIconsImg from '@/assets/img/aimake/style/vector/Geometricicon.jpg';
import styleVectorLineIconImg from '@/assets/img/aimake/style/vector/Lineicon.jpg';
import styleVectorSilhouetteImg from '@/assets/img/aimake/style/vector/Silhouette.jpg';
import styleVectorStripesImg from '@/assets/img/aimake/style/vector/Stripes.jpg';

// 矢量
export const vectorgraphicsStyleList = [
  {
    name: 'Line icon',
    name_zh: '线条图标',
    key: 'Line icon',
    img: styleVectorLineIconImg,
    img_b: styleB,
    scene: generateMethodMap.vectorgraphics,
    type: styleTypeMap.vectorgraphics,
    advanced: false,
    isVector: true,
  },
  {
    name: '2D graphics',
    name_zh: '平面图形',
    key: '2D graphics',
    img: styleVector2DGraphicImg,
    img_b: styleB,
    scene: generateMethodMap.vectorgraphics,
    type: styleTypeMap.vectorgraphics,
    advanced: false,
    isVector: true,
  },
  {
    name: 'Silhouette',
    name_zh: '剪影',
    key: 'Silhouette',
    img: styleVectorSilhouetteImg,
    img_b: styleB,
    scene: generateMethodMap.vectorgraphics,
    type: styleTypeMap.vectorgraphics,
    advanced: false,
    isVector: true,
  },
  // {
  //   name: 'Primitive',
  //   name_zh: '原始图案',
  //   key: 'Primitive',
  //   img: styleA,
  //   img_b: styleB,
  //   scene: generateMethodMap.vectorgraphics,
  //   type: styleTypeMap.vectorgraphics,
  //   advanced: false,
  // },
  {
    name: 'Stripes',
    name_zh: '条纹',
    key: 'Stripes',
    img: styleVectorStripesImg,
    img_b: styleB,
    scene: generateMethodMap.vectorgraphics,
    type: styleTypeMap.vectorgraphics,
    advanced: false,
    isVector: true,
  },
  {
    name: 'Geometric icon',
    name_zh: '几何图标',
    key: 'Geometric icon',
    img: styleVectorGeometricIconsImg,
    img_b: styleB,
    scene: generateMethodMap.vectorgraphics,
    type: styleTypeMap.vectorgraphics,
    advanced: false,
    isVector: true,
  },
  {
    name: 'Fibonacci',
    name_zh: '斐波那契',
    key: 'Fibonacci',
    img: styleVectorFibonacciImg,
    img_b: styleB,
    scene: generateMethodMap.vectorgraphics,
    type: styleTypeMap.vectorgraphics,
    advanced: false,
    isVector: true,
  },
];

import styleLaserart1970sArtImg from '@/assets/img/aimake/style/laserart/1970sArt.jpg';
import styleLaserart3DContourImg from '@/assets/img/aimake/style/laserart/3DContour.jpg';
import styleLaserartBlockPrintingImg from '@/assets/img/aimake/style/laserart/BlockPrinting.jpg';
import styleLaserartChalkDrawingImg from '@/assets/img/aimake/style/laserart/ChalkDrawing.jpg';
import styleLaserartCoatOfArmsImg from '@/assets/img/aimake/style/laserart/CoatOfArms.jpg';
import styleLaserartDoodleImg from '@/assets/img/aimake/style/laserart/Doodle.jpg';
import styleLaserartDotMatrixImg from '@/assets/img/aimake/style/laserart/DotMatrix.jpg';
import styleLaserartDraftImg from '@/assets/img/aimake/style/laserart/Draft.jpg';
import styleLaserartDuskImg from '@/assets/img/aimake/style/laserart/Dusk.png';
import styleLaserartFolkArtImg from '@/assets/img/aimake/style/laserart/FolkArt.jpg';
import styleLaserartForestImg from '@/assets/img/aimake/style/laserart/Forest.png';
import styleLaserartGeometricLineImg from '@/assets/img/aimake/style/laserart/GeometricLine.jpg';
import styleLaserartGeometricPatternImg from '@/assets/img/aimake/style/laserart/GeometricPattern.jpg';
import styleLaserartHologramImg from '@/assets/img/aimake/style/laserart/Hologram.jpg';
import styleLaserartLineArtImg from '@/assets/img/aimake/style/laserart/LineArt.jpg';
import styleLaserartLowReliefImg from '@/assets/img/aimake/style/laserart/LowRelief.jpg';
import styleLaserartMarbleSculptureImg from '@/assets/img/aimake/style/laserart/MarbleSculpture.jpg';
import styleLaserartMedievalCrestImg from '@/assets/img/aimake/style/laserart/MedievalCrest.jpg';
import styleLaserartMidnightImg from '@/assets/img/aimake/style/laserart/Midnight.png';
import styleLaserartMuralPaintingImg from '@/assets/img/aimake/style/laserart/MuralPainting.jpg';
import styleLaserartPictureBookImg from '@/assets/img/aimake/style/laserart/PictureBook.jpg';
import styleLaserartPrimitiveImg from '@/assets/img/aimake/style/laserart/Primitive.jpg';
import styleLaserartRealisticImg from '@/assets/img/aimake/style/laserart/Realistic.jpg';
import styleLaserartRipplesImg from '@/assets/img/aimake/style/laserart/Ripples.jpg';
import styleLaserartSharpEdgeImg from '@/assets/img/aimake/style/laserart/SharpEdge.jpg';
import styleLaserartSmokeEffectImg from '@/assets/img/aimake/style/laserart/SmokeEffect.jpg';
import styleLaserartStainedGlassImg from '@/assets/img/aimake/style/laserart/StainedGlass.jpg';
import styleLaserartStarryNightImg from '@/assets/img/aimake/style/laserart/StarryNight.jpg';
import styleLaserartSteampunkImg from '@/assets/img/aimake/style/laserart/Steampunk.jpg';
import styleLaserartVolcanoImg from '@/assets/img/aimake/style/laserart/Volcano.png';
import styleLaserartWallArtImg from '@/assets/img/aimake/style/laserart/WallArt.jpg';
import styleLaserartWoodCarvingImg from '@/assets/img/aimake/style/laserart/WoodCarving.jpg';
import styleLaserartZentangleImg from '@/assets/img/aimake/style/laserart/Zentangle.jpg';

// 激光艺术
export const laserartStyleList = [
  {
    name: 'Low relief',
    name_zh: '浅浮雕',
    key: 'Low relief',
    img: styleLaserartLowReliefImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.threeD,
    advanced: false,
  },

  {
    name: 'Realistic',
    name_zh: '写实',
    key: 'Realistic',
    img: styleLaserartRealisticImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.threeD,
    advanced: false,
  },

  {
    name: 'Smoke effect',
    name_zh: '烟雾',
    key: 'Smoke effect',
    img: styleLaserartSmokeEffectImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.threeD,
    advanced: false,
  },

  {
    name: 'Coat of arms',
    name_zh: '立体纹章',
    key: 'Coat of arms',
    img: styleLaserartCoatOfArmsImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.threeD,
    advanced: false,
  },

  {
    name: 'Wood carving',
    name_zh: '漩涡木雕',
    key: 'Wood carving',
    img: styleLaserartWoodCarvingImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.threeD,
    advanced: false,
  },

  {
    name: 'Steampunk',
    name_zh: '蒸汽朋克',
    key: 'Steampunk',
    img: styleLaserartSteampunkImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.threeD,
    advanced: false,
  },

  {
    name: 'Marble sculpture',
    name_zh: '石雕',
    key: 'Marble sculpture',
    img: styleLaserartMarbleSculptureImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.threeD,
    advanced: false,
  },

  {
    name: 'Hologram',
    name_zh: '全息图',
    key: 'Hologram',
    img: styleLaserartHologramImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.threeD,
    advanced: false,
  },

  {
    name: 'Ripples',
    name_zh: '波纹',
    key: 'Ripples',
    img: styleLaserartRipplesImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Geometric pattern',
    name_zh: '几何形状',
    key: 'Geometric pattern',
    img: styleLaserartGeometricPatternImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Picture book',
    name_zh: '儿童绘本',
    key: 'Picture book',
    img: styleLaserartPictureBookImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Line art',
    name_zh: '线稿艺术',
    key: 'Line art',
    img: styleLaserartLineArtImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Starry night',
    name_zh: '徽章艺术',
    key: 'Starry night',
    img: styleLaserartStarryNightImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Dot matrix',
    name_zh: '点阵',
    key: 'Dot matrix',
    img: styleLaserartDotMatrixImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Medieval crest',
    name_zh: '中世纪纹章',
    key: 'Medieval crest',
    img: styleLaserartMedievalCrestImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: '3D contour',
    name_zh: '三维轮廓',
    key: '3D contour',
    img: styleLaserart3DContourImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Primitive',
    name_zh: '原始图案',
    key: 'Primitive',
    img: styleLaserartPrimitiveImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Sharp edge',
    name_zh: '未来边锋',
    key: 'Sharp edge',
    img: styleLaserartSharpEdgeImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Stained glass',
    name_zh: '彩色玻璃书',
    key: 'Stained glass',
    img: styleLaserartStainedGlassImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Block printing',
    name_zh: '雕版印刷',
    key: 'Block printing',
    img: styleLaserartBlockPrintingImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Chalk drawing',
    name_zh: '粉笔',
    key: 'Chalk drawing',
    img: styleLaserartChalkDrawingImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Draft',
    name_zh: '草图',
    key: 'Draft',
    img: styleLaserartDraftImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Geometric line',
    name_zh: '几何线条',
    key: 'Geometric line',
    img: styleLaserartGeometricLineImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Zentangle',
    name_zh: '禅绕画',
    key: 'Zentangle',
    img: styleLaserartZentangleImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Folk art',
    name_zh: '民间艺术',
    key: 'Folk art',
    img: styleLaserartFolkArtImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.color,
    advanced: false,
  },

  {
    name: 'Doodle',
    name_zh: '手绘涂鸦',
    key: 'Doodle',
    img: styleLaserartDoodleImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.color,
    advanced: false,
  },

  {
    name: 'Wall art',
    name_zh: '墙绘艺术',
    key: 'Wall art',
    img: styleLaserartWallArtImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.color,
    advanced: false,
  },

  {
    name: '1970s art',
    name_zh: '1970s年代',
    key: '1970s art',
    img: styleLaserart1970sArtImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.color,
    advanced: false,
  },

  {
    name: 'Mural painting',
    name_zh: '壁画',
    key: 'Mural painting',
    img: styleLaserartMuralPaintingImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.color,
    advanced: false,
  },

  {
    name: 'Forest',
    name_zh: '绿色系',
    key: 'Forest',
    img: styleLaserartForestImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.screenart,
    advanced: false,
  },

  {
    name: 'Dusk',
    name_zh: '黄色系',
    key: 'Dusk',
    img: styleLaserartDuskImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.screenart,
    advanced: false,
  },

  {
    name: 'Volcano',
    name_zh: '红色系',
    key: 'Volcano',
    img: styleLaserartVolcanoImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.screenart,
    advanced: false,
  },

  {
    name: 'Midnight',
    name_zh: '蓝色系',
    key: 'Midnight',
    img: styleLaserartMidnightImg,
    img_b: styleB,
    scene: generateMethodMap.laserart,
    type: styleTypeMap.screenart,
    advanced: false,
  },
];

// style列表
export const styleList = [...imageTextMixStyleList, ...artwordStyleList, ...vectorgraphicsStyleList, ...laserartStyleList];

// 消耗积分配置
export const costCreditsMap = {
  // 激光艺术
  [generateMethodMap.laserart]: {
    def: 10,
    advanced: 10,
  },
  // 矢量
  [generateMethodMap.vectorgraphics]: {
    def: 10,
    advanced: 10,
  },
  // 艺术字
  [generateMethodMap.artword]: {
    def: 10,
    advanced: 10,
  },
  // 图文混排
  [generateMethodMap.imageTextMix]: {
    def: 10,
    advanced: 10,
  },
  // 浮雕
  [generateMethodMap.relief]: {
    def: 20,
    advanced: 20,
  },
  // 肖像线稿
  [generateMethodMap.handpainted]: {
    def: 20,
    advanced: 20,
  },
};

import uploadCropPicture from '@/assets/img/aimake/generate/uploadCropPicture.png';
import uploadCropSketch from '@/assets/img/aimake/generate/uploadCropSketch.png';

// 生成方式配置
export const sceneOptionsMap = {
  // 激光艺术
  [generateMethodMap.laserart]: {
    imageCount: {
      list: [2, 4],
      def: 4,
      show: true,
    },
  },
  // 矢量
  [generateMethodMap.vectorgraphics]: {
    imageCount: {
      list: [2, 4],
      def: 4,
      show: true,
    },
  },
  // 艺术字
  [generateMethodMap.artword]: {
    imageCount: {
      list: [1, 4],
      def: 4,
      show: false,
    },
    promptTitle: 'Text to display',
    promptLength: 50,
  },
  // 图文混排
  [generateMethodMap.imageTextMix]: {
    imageCount: {
      list: [1, 4],
      def: 4,
      show: false,
    },
    promptTitle: 'Image description',
    promptLength: 300,
  },
  // 浮雕
  [generateMethodMap.relief]: {
    imageCount: {
      list: [1],
      def: 1,
      show: false,
    },
    uploadConfig: {
      text: 'Upload Image',
      loading: 'Image uploading',
      title: 'Crop Image',
      ratios: ['1:1', '4:7', '7:4'],
      ratioDesc: 'Please select the aspect ratio for cropping',
      modeList: [
        { name: 'Sketch', key: 'LineArt', img: uploadCropSketch },
        { name: 'Picture', key: 'Usual', img: uploadCropPicture },
      ],
    },
    promptTitle: 'Description (optional)',
  },
  // 肖像线稿
  [generateMethodMap.handpainted]: {
    imageCount: {
      list: [1, 2],
      def: 1,
      show: false,
    },
    uploadConfig: {
      ratios: ['1:1', '3:4'],
      tips: 'Photo requirements: Please upload a high-resolution, close-up, frontal photo of a single individual',
    },
    promptTitle: 'Description (optional)',
  },
};

import logoLayoutImg2 from '@/assets/img/aimake/generate/LogoLayout2.png';
import logoLayoutImg3 from '@/assets/img/aimake/generate/LogoLayout3.png';
import logoLayoutImg4 from '@/assets/img/aimake/generate/LogoLayout4.png';

// logo布局类型配置
export const logoLayoutTypeList = [
  { name: 'Text below', img: logoLayoutImg2, key: 'Text below' },
  { name: 'Center text', img: logoLayoutImg3, key: 'Center text' },
  { name: 'Surrounded below', img: logoLayoutImg4, key: 'Surrounded below' },
];

// 图片比例配置
export const imageRatioOptionsMap = {
  [generateMethodMap.imageTextMix]: [
    { name: '1:1', key: '1:1', w: 40, h: 40 },
    { name: '9:16', key: '9:16', w: 25, h: 40 },
    { name: '16:9', key: '16:9', w: 40, h: 25 },
  ],
  [generateMethodMap.artword]: [
    { name: '1:1', key: '1:1', w: 40, h: 40 },
    { name: '9:16', key: '9:16', w: 25, h: 40 },
    { name: '16:9', key: '16:9', w: 40, h: 25 },
  ],
  def: [
    { name: '1:1', key: '1:1', w: 40, h: 40 },
    { name: '4:7', key: '4:7', w: 25, h: 40 },
    { name: '7:4', key: '7:4', w: 40, h: 25 },
  ],
};

// 生成方式对应的prompt引导输入
export const promptPlaceholderMap = {
  def: "Describe your image. For example, 'a bear in sunglasses'",
  [generateMethodMap.handpainted]: 'Try describing expressions and attire, such as: smiling, wearing a hat.',
  [generateMethodMap.relief]: 'Describe what is to be generated ',
  [generateMethodMap.imageTextMix]: 'Please describe the logo pattern you want.',
  [generateMethodMap.artword]: 'Enter a sentence you like, for example, I love you.',
};

// 生成方式对应的固定图片数量
export const imageCountMap = {
  [generateMethodMap.artimage]: 4,
  [generateMethodMap.screenart]: 4,
  [generateMethodMap.imageTextMix]: 4,
  [generateMethodMap.artword]: 4,
};

// ban图提示
export const banImageTips = {
  [generateMethodMap.imageTextMix]: ['Image generation failed.', 'Points returned, please try again.'],
  [generateMethodMap.artword]: ['Image generation failed.', 'Points returned, please try again.'],
  def: ['Image blocked', 'due to inappropriate content'],
};

import reliefTypeImg1 from '@/assets/img/aimake/generate/reliefType1.png';
import reliefTypeImg2 from '@/assets/img/aimake/generate/reliefType2.png';

// 浮雕类型配置
export const reliefTypeList = [
  { name: 'Emboss', img: reliefTypeImg1, key: false },
  { name: 'Deboss', img: reliefTypeImg2, key: true },
];

import UploadEx1 from '@/assets/img/aimake/generate/uploadEx1.png';
import UploadEx2 from '@/assets/img/aimake/generate/uploadEx2.png';
import UploadEx3 from '@/assets/img/aimake/generate/uploadEx3.png';
import UploadEx4 from '@/assets/img/aimake/generate/uploadEx4.png';
import UploadEx5 from '@/assets/img/aimake/generate/uploadEx5.png';
import UploadEx6 from '@/assets/img/aimake/generate/uploadEx6.png';
import UploadEx7 from '@/assets/img/aimake/generate/uploadEx7.png';
import uploadErrorIconImg from '@/assets/img/aimake/msg_error.png';
import uploadRightIconImg from '@/assets/img/aimake/msg_success.png';

// 图片上传弹窗示例
export const imageUploadExamplesMap = {
  [generateMethodMap.handpainted]: {
    isShowInPop: true,
    title: 'Example photo',
    list: [
      {
        text: 'Single frontal face',
        img: UploadEx1,
        icon: uploadRightIconImg,
      },
      {
        text: 'Not a frontal face',
        img: UploadEx2,
        icon: uploadErrorIconImg,
      },
      {
        text: 'Part covered',
        img: UploadEx3,
        icon: uploadErrorIconImg,
      },
      {
        text: 'Too blurred',
        img: UploadEx4,
        icon: uploadErrorIconImg,
      },
    ],
  },
  [generateMethodMap.relief]: {
    isShowInPop: false,
    icon: 'icon-infomation',
    title: 'Recommended to use line art images for optimal results',
    list: [
      {
        text: 'Example1',
        img: UploadEx5,
        icon: '',
      },
      {
        text: 'Example2',
        img: UploadEx6,
        icon: '',
      },
      {
        text: 'Example3',
        img: UploadEx7,
        icon: '',
      },
    ],
  },
};

export const ratioIconMap = {
  '1:1': 'icon-square',
  '3:4': 'icon-rectangle',
  '4:3': 'icon-rectangle-2',
  '4:7': 'icon-rectangle',
  '7:4': 'icon-rectangle-2',
  '9:16': 'icon-rectangle',
  '16:9': 'icon-rectangle-2',
} as any;
