import { request, REQUEST_SEVER } from '@/utils/request';
// 搜索
export const doSearchApi = (params: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/search',
    method: 'GET',
    params,
  });
};
export const getSearchResultCountApi = (params: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/search/count',
    method: 'GET',
    params,
  });
};
// 搜索热词
export const getSearchHotWordsApi = () => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/search/hotwords',
    method: 'GET',
  });
};
// 热门搜索结果
export const getSearchHotResWordsApi = () => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/search/hot-results',
    method: 'GET',
  });
};
// 搜索词建议
export const getSearchSuggestApi = (params: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/search/suggest',
    method: 'GET',
    params,
  });
};
// 搜索操作上报
export const getSearchActionApi = (data: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/search/action',
    method: 'POST',
    data,
  });
};
